<template>
  <div>
    <TheYaTeCommonHeader />
    <TheYaTeCommonNote class="co-bg-gray-6" />
    <main class="bg-co-gray-200">
      <div class="co-container-lg py-7 lg:flex lg:py-12">
        <div class="px-4 lg:w-9/12">
          <section class="co-container-sm mb-4 bg-co-gray-50 px-5 pb-5 pt-[3.25rem] shadow sm:px-10 sm:py-[3.25rem] lg:mb-0">
            <slot />
          </section>
        </div>
        <div class="px-4 lg:w-3/12">
          <YaTeCommonSideMenu />
        </div>
      </div>
    </main>
    <TheYaTeCommonFooterSimple />
  </div>
</template>

<script setup lang="ts">
import TheYaTeCommonHeader from '~/components/YaTe/YaTeCommon/Header/TheYaTeCommonHeader'
import TheYaTeCommonNote from '~/components/YaTe/YaTeCommon/TheYaTeCommonNote'
import YaTeCommonSideMenu from '~/components/YaTe/YaTeCommon/YaTeCommonSideMenu.vue'
import TheYaTeCommonFooterSimple from '~/components/YaTe/YaTeCommon/Footer/TheYaTeCommonFooterSimple'

useHead({
  htmlAttrs: {
    class: '!bg-black',
    'data-theme': 'lineyahoo',
  },
  bodyAttrs: {
    class: 'bg-co-gray-200',
  },
  link: [
    {
      hid: "favicon",
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon-yate.ico',
    },
  ],
})
</script>
