<template>
  <ul class="te-side-menu co-container-sm mx-auto list-none p-0">
    <li class="border-b border-co-gray-300">
      <a
        class="block p-3 text-[0.75rem] leading-[1.4286] text-co-gray-700 no-underline hover:bg-co-gray-300 hover:text-co-gray-700 md:text-[1rem]"
        href="/ly/contact"
      >
        お問い合わせ
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="block p-3 text-[0.75rem] leading-[1.4286] text-co-gray-700 no-underline hover:bg-co-gray-300 hover:text-co-gray-700 md:text-[1rem]"
        href="/ly/terms"
      >
        利用規約
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="block p-3 text-[0.75rem] leading-[1.4286] text-co-gray-700 no-underline hover:bg-co-gray-300 hover:text-co-gray-700 md:text-[1rem]"
        href="/ly/privacypolicy"
      >
        プライバシーポリシー
      </a>
    </li>
    <li class="border-b border-co-gray-300">
      <a
        class="block p-3 text-[0.75rem] leading-[1.4286] text-co-gray-700 no-underline hover:bg-co-gray-300 hover:text-co-gray-700 md:text-[1rem]"
        href="/ly/law"
      >
        特定商取引法に関する表示
      </a>
    </li>
    <li>
      <a
        class="block p-3 text-[0.75rem] leading-[1.4286] text-co-gray-700 no-underline hover:bg-co-gray-300 hover:text-co-gray-700 md:text-[1rem]"
        href="/ly/login"
      >
        受講生ログイン
      </a>
    </li>
  </ul>
</template>
